// Individual modules
var bravo = bravo || {};
bravo.utilities = (function () {
	'use strict';

	// Returns a function, that, as long as it continues to be invoked, will not
	// be triggered. The function will be called after it stops being called for
	// N milliseconds. If `immediate` is passed, trigger the function on the
	// leading edge, instead of the trailing.
	const debounce = (func, wait, immediate) => {
		let timeout;

		return () => {
			let context = this,
				args = arguments;
			const later = () => {
				timeout = null;
				if (!immediate) {
					func.apply(context, args);
				}
			};
			let callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) {
				func.apply(context, args);
			}
		};
	};

	return {
		debounce: debounce
	};
})();
/* globals Swiper */
bravo.swiper = (function () {
	'use strict';

	const initialise = () => {
		const $homeSwiper = $('#swiper-home');

		if (!$homeSwiper) {
			return;
		}

		const videos = document.querySelectorAll('#swiper-home video');
		let hasBeenInteractedWith = false,
			canPlayWebm;

		// need next/prev navigation to go to first/last slide, but NOT use the 'loop' parameter of swiper since it screws up the indexing with its duped slides
		const $prevEl = $homeSwiper.find('.swiper-button-prev'),
			$nextEl = $homeSwiper.find('.swiper-button-next');
		//

		const restartVideos = () => {
			videos.forEach((vid) => {
				vid.currentTime = 0;
			});
		};

		// videos are to loop only if the user is partway through a manual transition
		const enableDisableVideoLoops = ({ enable, disable } = {}) => {
			videos.forEach((vid) => {
				if (enable) {
					vid.loop = true;
				}

				if (disable) {
					vid.loop = false;
				}
			});
		};
		//

		var homeSwiper = new Swiper($homeSwiper, {
			pagination: {
				el: '.swiper-pagination',
				clickable: true
			},
			slidesPerView: 1,
			// am emulating this for custom looping (using 'loop: true' screws up the indexing)
			// navigation: {
			// 	nextEl: '.swiper-button-next',
			// 	prevEl: '.swiper-button-prev'
			// },

			on: {
				touchStart: () => {
					enableDisableVideoLoops({ enable: true });
				},
				touchEnd: () => {
					hasBeenInteractedWith = true;
					enableDisableVideoLoops({ disable: true });
					videos[homeSwiper.activeIndex].play();
				},
				slideChangeTransitionEnd: () => {
					restartVideos();
					videos[homeSwiper.activeIndex].play();
					homeSwiper.navigation.update('enabled');
				}
			}
		});

		const goToPrevSlide = () => {
			if (!homeSwiper.isBeginning) {
				homeSwiper.slidePrev();
			} else {
				homeSwiper.slideTo(homeSwiper.slides.length - 1);
			}
		};

		const goToNextSlide = () => {
			if (!homeSwiper.isEnd) {
				homeSwiper.slideNext();
			} else {
				homeSwiper.slideTo(0);
			}
		};

		// for prev/next navigation
		$prevEl.click(() => {
			goToPrevSlide();
		});

		$nextEl.click(() => {
			goToNextSlide();
		});
		//

		videos.forEach((vid) => {
			vid.onended = () => {
				if (!hasBeenInteractedWith && !vid.loop) {
					goToNextSlide();
				}
			};

			canPlayWebm = vid.canPlayType('video/webm');
		});

		// fun code to detect if browser can play webm, and use the canvas hack for removing the discoloured video background from the fallback mp4 videos
		// https://medium.com/swivelhq/how-to-solve-video-color-inconsistencies-across-browsers-a35efeb95196
		if (!canPlayWebm) {
			// Toggle this boolean to hide/show the original video alongside the canvas version of the video.
			const hideOrig = true;

			// draws the current frame from the video, then sets a timeout to do it again (roughly 30 FPS).
			const drawFrame = (v, c) => {
				if (v.paused || v.ended) {
					return false;
				}
				var w = v.videoWidth;
				var h = v.videoHeight;
				c.canvas.width = w;
				c.canvas.height = h;

				c.drawImage(v, 0, 0, w, h);
				v.timeout = setTimeout(drawFrame, 30, v, c);
			};

			// creates a canvas element, inserts it, and begins drawing video frames
			videos.forEach((v) => {
				var canvas = document.createElement('canvas');
				var ctx = canvas.getContext('2d');
				v.parentNode.appendChild(canvas);
				if (hideOrig) {
					v.classList.add('sr-only');
				}

				drawFrame(v, ctx);

				v.addEventListener('playing', function () {
					if (typeof v.timeout === 'number') {
						clearTimeout(v.timeout);
					}
					drawFrame(this, ctx);
				});
			});
		}
	};

	return {
		init: initialise
	};
})();